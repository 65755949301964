import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { setContext } from "@apollo/client/link/context";
import TimeoutLink from "apollo-link-timeout";
import { createClient } from "graphql-ws";

const createApolloClient = () => {
  const timeout = 90000; // Set the desired timeout value in milliseconds

  const fetchWithTimeout = async (url, options) => {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), timeout);
    const response = await fetch(url, {
      ...options,
      signal: controller.signal,
    });
    clearTimeout(timeoutId);
    return response;
  };

  const httpLink = createHttpLink({
    uri: process.env.NEXT_PUBLIC_BACKEND_URL_DEV,
    fetch: fetchWithTimeout,
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    const mainToken = localStorage.getItem("mainToken")
      ? localStorage.getItem("mainToken")
      : "";
    const authTok = localStorage.getItem("authTok"); 

    const authToke = localStorage.getItem("authToke");

    const authToken = localStorage.getItem("authToken");
    
    const authFinalToken = authTok || authToke || authToken;

    const ltTok = localStorage.getItem("LTtok")
      ? localStorage.getItem("LTtok")
      : "";
    const ltToke = localStorage.getItem("LTtoke")
      ? localStorage.getItem("LTtoke")
      : "";
    const LTtoken = localStorage.getItem("LTtoken")
      ? localStorage.getItem("LTtoken")
      : "";

    const dispatcherToken = localStorage.getItem("DispatcherToken")
      ? localStorage.getItem("DispatcherToken")
      : "";
    const dispatcherTempToken = localStorage.getItem("DispatcherTempToken")
      ? localStorage.getItem("DispatcherTempToken")
      : "";

    let auth = "";
    if (token && token !== "") {
      auth = "Bearer " + token;
    } else if (mainToken && mainToken !== "") {
      auth = "Bearer " + mainToken;
    } else if (LTtoken && LTtoken !== "") {
      auth = "Bearer " + LTtoken;
    } else if (dispatcherToken && dispatcherToken !== "") {
      auth = "Bearer " + dispatcherToken;
    } else if (dispatcherTempToken && dispatcherTempToken !== "") {
      auth = "Bearer " + dispatcherTempToken;
    } else if (ltTok && ltTok !== "") {
      auth = "Bearer " + ltTok;
    } else if (ltToke && ltToke !== "") {
      auth = "Bearer " + ltToke;
    } else if (authFinalToken && authFinalToken !== "") {
      console.log("Found Auth Final Token");
      auth = "Bearer " + authFinalToken;
    } 

    return {
      headers: {
        ...headers,
        authorization: auth,
      },
    };
  });

  const createWSLink = () => {
    const isServer = typeof window === "undefined";

    if (isServer) {
      // Server-side (Node.js)
      return null; // Return null to disable WebSocket on the server-side
    } else {
      // Client-side

      const wsLink = new GraphQLWsLink(
        createClient({
          url: process.env.NEXT_PUBLIC_BACKEND_WS_URL_DEV,
        })
      );

      return wsLink;
    }
  };

  const timeoutLink = new TimeoutLink(timeout);

  const ssrMode = typeof window === "undefined";
  let link;
  if (ssrMode) {
    // Server-side rendering
    link = authLink.concat(httpLink);
  } else {
    // Client-side rendering
    const wsLink = createWSLink();
    if (wsLink) {
      // WebSocketLink is enabled
      link = split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
          );
        },
        wsLink,
        authLink.concat(timeoutLink).concat(httpLink)
      );
    } else {
      // WebSocketLink is disabled
      link = authLink.concat(timeoutLink).concat(httpLink);
    }
  }

  return new ApolloClient({
    ssrMode,
    link,
    cache: new InMemoryCache({ addTypename: false }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "no-cache",
      },
      mutate: {
        fetchPolicy: "no-cache",
      },
    },
  });
};

export default createApolloClient;
